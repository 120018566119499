export const BUNDLE_DASHBOARD_TASKS_BASE = "/api/v3.1/web-client/dashboard/";
export const SEND_SMS_DOWNLOAD_LINK = "/api/v3/downloads/sms_link";
export const CANCEL_TASK = "/api/v3/web-client/dashboard/cancel";
export const CANCEL_TASK_V2 = "/api/v3.1/web-client/dashboard/cancel";
export const CANCEL_JOB_BUNDLE =
  "/api/v3/web-client/job_bundles/:job_bundle_id/cancel";
export const DASHBOARD_TASK_COUNTS = "/api/v3/web-client/dashboard/counts";
export const REVIEW_TASK_CREATE = "/api/v3.3/jobs/:job_id/rate.json";
export const REVIEW_TASK_UPDATE =
  "/api/v3.3/jobs/:job_id/reviews/:review_id/review.json";
export const RETRIEVE_SPECIFIC_JOB_ID =
  "/api/v3.2/web-client/dashboard/tasks/:job_id/tasker/:rabbit_id";
export const POST_JOB_FOLLOWUP = "/api/v3/followups/review.json";
export const JOB_RESCHEDULE_APPT =
  "/api/v3/jobs/:job_id/appointments/:appointment_id/reschedule.json";
export const JOB_RESCHEDULE_APPT_V3_1 =
  "/api/v3.1/jobs/:job_id/appointments/:appointment_id/reschedule.json";
export const JOB_RESCHEDULE_AVAILABILITY_V3_1 =
  "/api/v3.1/mobile-client/jobs/:job_id/rabbits/:rabbit_id/reschedule_availability";
export const JOB_RESCHEDULE_DATES =
  "/api/v3/jobs/:job_id/appointments/:appointment_id/dates.json";
export const JOB_SKIP_RECURRING_APPT =
  "/api/v3/jobs/:job_id/appointments/:appointment_id/skip.json";
export const APPLY_PROMO_CODE_TO_JOB =
  "/api/v3/jobs/:job_id/promotions/apply.json";
export const VALIDATE_PROMO_CODE = "/api/v3/promotions/valid.json";
export const BOOTSTRAP = "/api/v3/web-client/bootstrap.json";

export const DASHBOARD_TASKS_METRO =
  "/api/v3/web-client/metro_task_template.json";

export const DASHBOARD_SMART_SEARCH_V2 = "/api/v3.1/web-client/term_search";

export const CREATE_JOB_DRAFT = "/api/v3/web-client/search_job_draft";
export const LOG_SEARCH_NAVIGATION = "/api/v3/web-client/log_search_navigation";
export const UPDATE_JOB_DRAFT_STATUS =
  "/api/v3/web-client/job_draft/:guid/update";
export const JOB_CHAT_THREAD = "/api/v3/jobs/:job_id/chat/:rabbit_id.json";
export const JOB_CONFERENCE_THREAD =
  "/api/v3/jobs/:job_id/conference/:rabbit_id.json";

export const FETCH_FAVORITE_TASKER_LIST =
  "/api/v3/poster_rabbit_relationships/favorite";
export const UPDATE_FAVORITE_TASKER_RELATIONSHIP =
  "/api/v3/poster_rabbit_relationships/favorite/:rabbit_id";

export const FETCH_PAST_TASKER_LIST =
  "/api/v3/poster_rabbit_relationships/past";

export const FETCH_HAS_DECLINE_INVOICES =
  "/api/v3/decline_invoice/has_submitted_invoices";

export const ANNOUNCEMENT_BANNER = "/api/v3/announcements";

export const CHAT_TOKEN_ENDPOINT = "/api/v3/account/tokens";

export const CHAT_STATUS_API = "/api/v3/chats";

export const POSTAL_CODE_SEARCH = "/api/v3/geospatial/valid_and_served.json";

export const PAY_DECLINE_INVOICE = (id) =>
  `/api/v3/decline_invoices/${id}/attempt_payment`;

export const PAY_JOB_INVOICE = (id) => `/api/v3/invoices/${id}/attempt_payment`;

export const JOB_DETAILS = (guid) =>
  `/api/v3.2/web-client/dashboard/tasks/${guid}`;

export const SECURE_IT_WAIVER = "/api/v3/ikea/secure_it/waive"

import { getGlobalConfiguration } from "./global-configuration";

// i18n HOSER - Add new countries here before rolling them out.
// Source of truth: `use_country_language_path_boolean` in
// `config/locale_driven/site.yml`.
const prefixlessPathCountries = ["us", "gb", "fr", "de", "pt", "it"];
const prefixedPathCountries = ["ca", "es"];

function isPathAbsolute(path) {
  return path[0] !== "/";
}

function isPathLocalized({ path, trCountry, trLanguage }) {
  return (
    !(trCountry && trLanguage) ||
    new RegExp("^/" + trCountry + "/" + trLanguage + "/").test(path)
  );
}

function isEnvironmentDevelopment() {
  return process.env.NODE_ENV === "development";
}

function isGatsby(windowGlobal) {
  const port = windowGlobal.location?.port;

  return isEnvironmentDevelopment() && port === "8000";
}

export function internalPath(path, internalPathContext = {}) {
  if (path === "/") {
    return path;
  }

  if (isPathAbsolute(path)) {
    return path;
  }

  const trCountry =
    internalPathContext.trCountry || getInternalPathContext().trCountry;
  const trLanguage =
    internalPathContext.trLanguage || getInternalPathContext().trLanguage;

  const localizedPath = isPathLocalized({ path, trCountry, trLanguage })
    ? path
    : `/${trCountry}/${trLanguage}${path}`;

  const windowGlobal = typeof window === "undefined" ? {} : window;
  const gatsby = isGatsby(windowGlobal);

  let origin = 'http://localhost:3000';
  if (!gatsby) {
    origin = windowGlobal.location?.origin ?? origin;
  }
  else if (process.env.LOCAL_DOMAIN !== undefined) {
    origin = `http://${process.env.LOCAL_DOMAIN}`;
  }

  return gatsby ? `${origin}${localizedPath}` : localizedPath;
}

function getInternalPathContext() {
  const { locale } = getGlobalConfiguration();
  const trLanguage = locale.slice(0, 2).toLowerCase();
  const trCountry = locale.slice(3, 5).toLowerCase();
  if (prefixlessPathCountries.includes(trCountry)) {
    return {};
  }
  if (prefixedPathCountries.includes(trCountry)) {
    return { trCountry, trLanguage };
  }
  throw Error(
    `Unexpected country code: ${trCountry}. Add it to either ` +
    "`prefixlessPathCountries` or `prefixedPathCountries` in " +
    "`packages/tr-web/apps/web/src/util/internalPath.js`."
  );
}
